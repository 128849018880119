import React from "react";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { colours, tabselected, tabselectedNopoint } from "../../consts.js";
import { useNavigate } from "react-router-dom";
import Utils from "../../utils.js";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import FeatherIcon from "feather-icons-react";
import CircularProgress from "@mui/material/CircularProgress";
import S3_Util from "../../utils/s3-folder-creation/s3-folder-utilty.js";

function Admin() {
  let navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [newCampDiag, setNewCampDiag] = React.useState(false);
  const [selectedTab] = React.useState("Companies");
  const [isAdmin, setIsadmin] = React.useState(false);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);

  async function countUsers(comp) {
    let dta = await Utils.GenAdmFunc("listUsersInGroup", {
      GroupName: comp.internalname,
      UserPoolId: "STRING_VALUE",
      Limit: 60,
    });
    // console.log({ dta });
    comp.users = [dta.Users].length;
    return comp;
  }

  async function getCompanyData() {
    Utils.sendProgressBar(true);
    setSpinner(true);
    let userData = await Utils.getUserGroupFromCognito();
    const user = userData; // Access the user object

    // Check if user groups include "admin"
    // console.log(user.isAdmin);
    if (user.includes("admin")) {
      setIsadmin(true);
    } else {
      return; // Exit if not an admin
    }

    if (user.isAdmin === null || user.isAdmin === false) navigate("/");
    else {
      let companyData = await Utils.genericList("listCompanies", {
        limit: 1000,
      });
      let companies = companyData.data.listCompanies.items;

      // await countUsers(companies[0].internalname);
      let countedcompany = await Promise.all(companies.map(countUsers));
      // console.log({ countedcompany });
      setData(countedcompany);
    }
    setSpinner(false);
  }

  React.useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        await getCompanyData(); // Your async data fetching function
        setDataLoaded(true); // Mark data as loaded
      };
      fetchData();
    }

    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, [dataLoaded]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <NewCampaignD1
        open={newCampDiag}
        onClose={async (inp) => {
          if (inp === null) setNewCampDiag(false);
          else {
            // Generate a sanitized folder name for S3
            const groupName = inp.cname
              .replace(/ /g, "")
              .replace(/[^a-zA-Z0-9]/g, "");

            // Create an S3 folder via Lambda
            await S3_Util.callLambda(groupName);

            await Utils.GenAdmFunc("createGroup", {
              GroupName: groupName,
              UserPoolId: "STRING_VALUE",
            });

            let admintracker = await Utils.genericGet("getCompany", "admin");

            let s = await Utils.genericMutation("createCompany", {
              name: inp.cname,
              contact: inp.phone,
              email: inp.email,
              status: "active",
              internalname: groupName,
              groups: [groupName,"admin"],
              trackers: admintracker.data.getCompany.trackers,
            });
            // console.log({ s });
            getCompanyData();

            setNewCampDiag(false);
          }
        }}
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FeatherIcon
          icon="award"
          style={{ marginLeft: 20, marginBottom: 10 }}
        />
        <div
          style={{ marginLeft: 5, cursor: "pointer" }}
          onClick={() => {
            navigate("/admin");
          }}
        >
          Admin /
        </div>
        <FeatherIcon icon="list" style={{ marginLeft: 5, marginBottom: 10 }} />
        <div className="menu-item-selected">Companies</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: colours.dividergraybottomborder,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
        >
          <div
            style={tabselected(selectedTab === "Companies")}
            onClick={() => {
              navigate("/admin");
            }}
          >
            Companies
          </div>
          <div
            style={tabselected(selectedTab === "Tracker admin")}
            onClick={() => {
              navigate("/trackeradmin");
            }}
          >
            Tracker admin
          </div>
          <div
            style={tabselected(selectedTab === "User setup")}
            onClick={() => {
              navigate("/adminusers");
            }}
          >
            User setup
          </div>
        </div>
      </div>
      <div className="vocalysed-home">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: 30,
              marginTop: 10,
            }}
          ></div>
          <div className="vocalysd-card vocalysd-table">
            <div style={{ alignItems: "end" }}>
              <Button
                style={{
                  textTransform: "none",
                  float: "right",
                  borderRadius: 20,
                  marginRight: 20,
                  marginTop: 10,
                  background: colours.primary100,
                }}
                variant="contained"
                onClick={() => setNewCampDiag(true)}
              >
                <FeatherIcon
                  icon="plus-circle"
                  style={{ color: colours.white, marginRight: 5 }}
                />
                Create company
              </Button>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Company name
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Contact
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Email
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        textAlign: "center",
                      }}
                    >
                      Status
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        textAlign: "center",
                      }}
                    >
                      # Users
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Usage
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Edit
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {spinner ? (
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <CircularProgress style={{ color: colours.primary100 }} />
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                ) : (
                  data.map((z, zidx) => (
                    <TableRow key={zidx} style={{}}>
                      <TableCell>
                        <div
                          style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                        >
                          {z.name}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            fontFamily: "Poppins",
                          }}
                        >
                          {z.contact || ""}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            fontFamily: "Poppins",
                          }}
                        >
                          {z.email || ""}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "Poppins",
                          textAlign: "center",
                        }}
                      >
                        <Chip
                          label={z.status === "active" ? "Active" : "Disabled"}
                          style={{
                            background: z.status
                              ? colours.green100
                              : colours.critical100,
                            color: colours.white,
                            lineHeight: "24px",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "Poppins",
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            display: "block",
                            flexDirection: "row",
                            alignItems: "center",
                            fontFamily: "Poppins",
                          }}
                        >
                          {z.users}
                        </div>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <FeatherIcon
                          icon="edit-2"
                          style={{
                            color: colours.subduedgray,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate("/organisationsetup?id=" + z.id)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;

function NewCampaignD1({ open, onClose }) {
  const [phone, setPhone] = React.useState("");
  const [cname, setCname] = React.useState("");
  const [email, setEmail] = React.useState("");

  return (
    <Dialog
      disableAutoFocus
      onClose={() => onClose(null)}
      onBackdropClick={() => {
        onClose(null);
      }}
      open={open}
      style={{
        padding: 0,

        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 30,
      }}
    >
      <DialogTitle style={{ padding: 0, minWidth: "600px", width: "600px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,

              fontFamily: "Poppins",
            }}
          >
            <div>Create company</div>

            <FeatherIcon
              icon="x"
              style={{ color: "#ddd", cursor: "pointer" }}
              onClick={() => onClose(null)}
            />
          </div>
          <Divider style={{}} />
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            jus: "space-between",
            alignItems: "center",
            padding: 20,
            fontFamily: "Poppins",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></div>
          <TextField
            id="outlined-basic"
            variant="outlined"
            style={{ width: "100%", marginTop: 20 }}
            label="Company name"
            InputLabelProps={{
              style: { color: "#84858a" },
            }}
            value={cname}
            onChange={(c) => {
              setCname(c.target.value);
            }}
          />
          <TextField
            id="outlined-basic"
            variant="outlined"
            style={{ width: "100%", marginTop: 20 }}
            label="Contact"
            InputLabelProps={{
              style: { color: "#84858a" },
            }}
            value={phone}
            onChange={(c) => {
              setPhone(c.target.value);
            }}
          />
          <TextField
            id="outlined-basic"
            variant="outlined"
            style={{ width: "100%", marginTop: 20 }}
            label="Email address"
            InputLabelProps={{
              style: { color: "#84858a" },
            }}
            value={email}
            onChange={(c) => {
              setEmail(c.target.value);
            }}
          />
        </div>
        <Divider style={{}} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 20,
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              textTransform: "none",
              float: "right",
              marginRight: 5,
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Poppins",
              background: colours.white,
              color: colours.primary100,
              border: "1px solid #ddd",
              boxShadow: "none",
            }}
            variant="contained"
            onClick={() => onClose(null)}
          >
            Cancel
          </Button>
          <Button
            style={{
              textTransform: "none",
              float: "right",
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Poppins",
              background: colours.primary100,
            }}
            variant="contained"
            onClick={() => {
              onClose({
                email,
                cname,
                phone,
              });
            }}
          >
            Create
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
