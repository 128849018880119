import React from "react";
import { TT } from "../../consts.js"; // Adjust path if needed

const ComplianceCard = ({ data, colours }) => {
  // Utility function to calculate the percentage
  const getCompliancePercentage = (trackerType) => {
    if (!data.trackerhits) return 0;
    const trackerData = data.trackerhits.find((z) => z.tracker === trackerType);
    return trackerData ? ((trackerData.hits / data.VShits.n) * 100).toFixed(0) : 0;
  };

  return (
    <div style={colours.vboxSmall} className="vocalysd-card">
      <div className="vocalysd-heading">
        Compliance
        <TT
          info={
            "Company Introduction and Recording Disclosures are key components of the Vocalysd Score, given an agent's minimum disclosure requirements. These metrics are determined by the detection of trackers in each conversation. A higher % indicates better adherence to standards."
          }
        />
      </div>
      <div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 16 }}>
          <div style={{ marginRight: 50 }}>
            <div style={{ fontSize: 12, color: "#999DAC" }}>Intro Company</div>
            <div style={{ fontSize: 30, fontWeight: "600" }}>
              {getCompliancePercentage("introcompany-detect")}%
            </div>
          </div>
          <div style={{ marginRight: 5 }}>
            <div style={{ fontSize: 12, color: "#999DAC" }}>Recording Disclosed</div>
            <div style={{ fontSize: 30, fontWeight: "600" }}>
              {getCompliancePercentage("recording-disclosure-detect")}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplianceCard;
