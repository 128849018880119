import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import IconButton from "@mui/material/IconButton";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { teal } from "@mui/material/colors";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

export const playlistFilters = [
    "Snippets",
    "WOW moments",
    "High risk",
    "Great interactions",
    "Poor interactions",
    "Other"
]

export const playlistFilterDescriptions = {
    snippets: "All bookmarks",
    wowMoments: "Exceptional moments such as great Agent engagement, Client satisfaction, call outcome, etc.",
    highRisk: "Critical issues that require attention as soon as possible such as threats of cancellation or legal action.",
    greatInteractions: "Interactions that model the general good practice expected in the call centre.",
    poorInterations: "Interactions that model the general bad practice to avoid in the call centre.",
    other: "Storage space for any unclassified snippets or if a suitable category does not exist. "
}

export const colours = {
    primary100: "#E7A500",
    primary20: "#FAEDCC",
    primary10: "#FDF6E5",
    primaryhover: "#D09500",
    black: "#000000",
    blackShaded: "#1C2531",
    white: "#fff",
    basegray: "#F8F9FD",
    dividergray: "#DEE2F1",
    subduedgray: "#999DAC",

    critical100: "#E85122",
    critical20: "#FADCD3",
    critical10: "#FDEEE9",
    
    success100: "#21A500",
    success20: "#D3EDCC",
    success10: "#E9F6E5",

    blue100: "#5092F4",
    blue20: "#DCE9FD",
    blue10: "#EDF4FE",
    blue5: "#F6FAFF",

    teal100: "#16C6C6",
    teal20: "#D3F5F5",
    teal10: "#E9FAFA",
    teal5: "#F4FCFC",

    green100: "#21A500",
    green20: "#D3EDCC",
    green10: "#E9F6E5",
    green5: "#F4FBF2",

    orange100: "#E87522",
    orange20: "#FAE3D3",
    orange10: "#FDF1E9",
    orange5: "#FEF8F4",

    purple100: "#9F40C1",
    purple20: "#ECD9F3",
    purple10: "#F5ECF9",
    purple5: "#FAF6FC",

    primary100bottomborder: "2px solid #E7A500",
    dividergraybottomborder: "2px solid #DEE2F1",
    vbox: {
        padding: 10,
        paddingLeft: 20,
        margin: 10,
        background: "#F8F9FD",
        boxShadow: "9px 9px 10px #D1D9E6, -9px -9px 10px #FFFFFF",
        borderRadius: "20px",
    },
    vboxSmall: {
        /*height: 120,*/
        width: "100%",
        /*padding: 10,
        paddingLeft: 20,
        margin: 10,
        background: "#F8F9FD",
        boxShadow: "9px 9px 10px #D1D9E6, -9px -9px 10px #FFFFFF",
        borderRadius: "20px",*/
    },
    vboxSmallSpacer: {
        height: 150,
        width: "50%",
        padding: 10,
        paddingLeft: 20,
        margin: 10,
    },
    vboxMedium: {
        height: 110,

        /*padding: 10,
        paddingLeft: 20,
        margin: 10,
        background: "#F8F9FD",
        boxShadow: "9px 9px 10px #D1D9E6, -9px -9px 10px #FFFFFF",
        borderRadius: "20px",*/

    },
    vboxMediumTall: {
        height: 450,
        /*fontFamily:"Poppins",
            padding: 10,
            paddingLeft: 20,
            margin: 10,
            background: "#F8F9FD",
            boxShadow: "9px 9px 10px #D1D9E6, -9px -9px 10px #FFFFFF",
            borderRadius: "20px",*/
    },
    vboxMediumTall2: {
        height: 340,
        /*fontFamily:"Poppins",
            padding: 10,
            paddingLeft: 20,
            margin: 10,
            background: "#F8F9FD",
            boxShadow: "9px 9px 10px #D1D9E6, -9px -9px 10px #FFFFFF",
            borderRadius: "20px",*/
    },
    vboxWide: {
        /*padding: 10,
        paddingLeft: 20,
        margin: 10,*/
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        /*background: "#F8F9FD",
        boxShadow: "9px 9px 10px #D1D9E6, -9px -9px 10px #FFFFFF",
        borderRadius: "20px",*/
    },
    divstyle1: {
        width: "100%",
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
    },
    avatarBox: {
        width: 40,
        marginRight: 20,
        height: 40,
        background: "#FFFFFF",
        boxShadow: "2px 2px 3px #D1D9E6, 2px 2px 3px #FFFFFF",
        borderRadius: " 22px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },

    menuStyle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textTransform: "none",
        justifyContent: "center",
        preventDefault: true,
        textDecoration: "none",
        height: 80,
        fontSize: 12,
        color: "#fff",
    },
    menuStyleSelected: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textTransform: "none",
        background: "#1C2531",
        borderLeft: "3px solid #E7A500",
        justifyContent: "center",
        preventDefault: true,
        textDecoration: "none",
        height: 80,
        fontSize: 12,
        color: "#fff",
    },
    tscriptEDicon: {
        width: 32,
        minWidth: 32,
        marginRight: 0,
        marginLeft: 5,
        height: 32,
        background: "#fff",

        boxShadow: "2px 2px 3px #D1D9E6, 2px 2px 3px #FFFFFF",
        borderRadius: " 22px",
        justifyContent: "center",
        color: "#E7A500",
        cursor: "pointer",
        alignItems: "center",
        display: "flex",
    },
    tscriptEDicon2: {
        width: 32,
        minWidth: 32,
        marginRight: 0,
        marginLeft: 0,
        height: 32,
        background: "#fff",

        boxShadow: "2px 2px 3px #D1D9E6, 2px 2px 3px #FFFFFF",
        borderRadius: " 22px",
        justifyContent: "center",
        color: "#E7A500",
        cursor: "pointer",
        alignItems: "center",
        display: "flex",
    },

    tscriptBMicon: {
        width: 20,
        minWidth: 20,
        marginRight: 5,
        marginLeft: 5,
        height: 20,
        background: "#fff",

        boxShadow: "2px 2px 3px #D1D9E6, 2px 2px 3px #FFFFFF",
        borderRadius: " 22px",
        justifyContent: "center",
        color: "#E7A500",
        alignItems: "center",
        display: "flex",
        cursor: "pointer",
    },
};

export function tabselected(selected) {
    return {
        borderBottom: selected ? colours.primary100bottomborder : null,
        color: selected ? colours.primary100 : colours.black,
        paddingBottom: 20,
        // width: 100,
        marginLeft: 30,
        fontWeight: "bold",
        fontSize: 14,
        textAlign: "center",
        cursor: "pointer",
    };
}

export function uploadTabSelected(selected) {
    return {
        borderBottom: selected ? colours.primary100bottomborder : null,
        color: selected ? colours.primary100 : colours.black, uploadTabSelected,
        fontWeight: "bold",
        fontSize: 14,
        cursor: "pointer",
    };
}

export function tabselectedNopoint(selected) {
    return {
        borderBottom: selected ? colours.primary100bottomborder : null,
        color: selected ? colours.primary100 : colours.black,
        paddingBottom: 20,
        // width: 100,
        marginLeft: 30,
        fontWeight: "bold",
        fontSize: 14,
        textAlign: "center",

    };
}
export function chipstyle(selected) {
    return {
        fontFamily: "Poppins",
        background: selected ? colours.green100 : colours.dividergray,
        color: selected ? colours.white : colours.black,
    };
}

export function TT({ info }) {
    return (

        <HtmlTooltip
            title={
                <React.Fragment>
                    <Typography color="inherit" style={{ fontFamily: "Poppins" }}>
                        {info}
                    </Typography>
                </React.Fragment>
            }
        >
            <IconButton
                style={{ marginTop: "-8px", marginLeft: "5px!important" }} >
                <FeatherIcon
                    icon="info"
                    style={{ color: "#e7a500", width: 15, height: 15 }}
                />
            </IconButton>
        </HtmlTooltip>

    );
}

export function TTT({ info }) {
    return (

        <HtmlTooltip
            title={
                <React.Fragment>
                    <Typography color="inherit" style={{ fontFamily: "Poppins" }}>
                        {info}
                    </Typography>
                </React.Fragment>
            }
        >
            <IconButton>
                <FeatherIcon
                    icon="info"
                    style={{ color: "#e7a500", width: 20, height: 20 }}
                />
            </IconButton>
        </HtmlTooltip>

    );
}
// let c;
// export default c = 3;
