import React from "react";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { colours } from "../../consts.js";
import FeatherIcon from "feather-icons-react";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useLocation } from "react-router-dom";
import Utils from "../../utils";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";

import CircularProgress from "@mui/material/CircularProgress";

function CampaignConversations() {
  let navigate = useNavigate();
  const [editagent, setEditagent] = React.useState(false);
  const [agentname, setagentname] = React.useState("");

  let location = useLocation();
  let camid = location.search.replace("?id=", "");
  const [campaignID] = React.useState(camid);
  const [data, setData] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);
  const [dataLoaded, setDataLoaded] = React.useState(false);

  async function getCampaignData() {
    Utils.sendProgressBar(true);
    setSpinner(true);
    let camids = location.search.replace("?id=", "");
    let list = [];
    let s = await Utils.genericListMOD(
      "listConversations",
      {
        filter: {
          campaignConversationsId: { eq: camids },
          archive: { ne: true },
        },
        limit: 1000,
      },
      true
    );
    list = list.concat(s.data.listConversations.items);
    setData(list);
    while (s.data.listConversations.nextToken !== null) {
      s = await Utils.genericListMOD(
        "listConversations",
        {
          filter: {
            campaignConversationsId: { eq: camids },
            archive: { ne: true },
          },
          limit: 1000,
          nextToken: s.data.listConversations.nextToken,
        },
        true
      );
      list = list.concat(s.data.listConversations.items);
      setData(list);
    }

    setSpinner(false);
  }

  React.useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        await getCampaignData(); // Your async data fetching function
        setDataLoaded(true); // Mark data as loaded
      };
      fetchData();
    }

    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, [dataLoaded]);

  return (
    <div className="vocalysed-home">
      <NewCampaignD1
        open={editagent}
        data={agentname}
        onClose={async (inp) => {
          if (inp === null) setEditagent(false);
          else {
            await Utils.genericMutation("updateConversation", {
              id: inp.id,
              agent: inp.agentname,
            });
            await getCampaignData();
            setEditagent(false);
          }
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          background: "#f8f9fd",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: 30,
              }}
            >
              {data.filter((z) => z.selected === true).length > 0 ? (
                <>
                  Conversations selected
                  <Chip
                    label={data.filter((z) => z.selected === true).length}
                    style={{
                      fontFamily: "Poppins",
                      marginLeft: 18,
                      marginRight: 18,
                      background: "#DEE2F1",
                      borderRadius: "26px",
                    }}
                    className="bold"
                  />
                  <FeatherIcon
                    icon="chevron-right"
                    style={{
                      color: colours.black,
                      marginRight: 18,
                    }}
                  />
                  <Button
                    style={{
                      textTransform: "none",
                      float: "right",
                      borderRadius: 20,
                      marginRight: 20,
                      background: colours.primary100,
                      fontFamily: "Poppins",
                    }}
                    onClick={async () => {
                      let d = data.filter((a) => a.selected).map((a) => a.id);
                      async function sendtoarchive(input) {
                        return await Utils.genericMutation(
                          "updateConversation",
                          {
                            id: input,
                            archive: true,
                          }
                        );
                      }
                      await Promise.all(d.map(sendtoarchive));
                      getCampaignData();
                    }}
                    variant="contained"
                  >
                    <FeatherIcon
                      icon="archive"
                      style={{ color: colours.white }}
                    />
                    Archive
                  </Button>
                </>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: 20,
                alignItems: "center",
                marginTop: 8,
              }}
            >
              {data.filter((z) => z.symblstatus === "completed").length} of{" "}
              {data.length} files processed
              <FeatherIcon
                icon="refresh-cw"
                onClick={getCampaignData}
                style={{
                  cursor: "pointer",
                  marginLeft: 12,
                  color: colours.primary100,
                  width: 20,
                  height: 20,
                }}
              />
            </div>
          </div>
          <div className="vocalysd-card vocalysd-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      <Checkbox
                        checked={
                          data.length === 0
                            ? false
                            : data
                                .map((q) => q.selected)
                                .filter((q) => q !== true).length === 0 || false
                        }
                        onClick={(e) => {
                          let d = Object.assign([], data);
                          d.forEach((q) => (q.selected = e.target.checked));
                          setData(d);
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      File
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Agent
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Call date
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Avg. Call Duration
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Avg. Vocalysd score
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      # of Alert hits
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {spinner ? (
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <CircularProgress
                        style={{
                          color: colours.primary100,
                          width: 20,
                          height: 20,
                        }}
                      />
                    </TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                ) : (
                  data
                    .sort((a, b) => {
                      if (a.createdAt > b.createdAt) return -1;
                      else if (a.createdAt < b.createdAt) return 1;
                      else return 0;
                    })
                    .map((z, zidx) => (
                      <TableRow
                        className={
                          z.selected
                            ? "MuiTableRow-root css-1q1u3t4-MuiTableRow-root selected-mui"
                            : "MuiTableRow-root css-1q1u3t4-MuiTableRow-root"
                        }
                        key={zidx}
                        style={{
                          cursor:
                            z.symblstatus === "completed" ? "pointer" : "auto",
                        }}
                        onClick={() => {
                          if (z.symblstatus === "completed")
                            navigate("/conversation?id=" + z.id);
                        }}
                      >
                        <TableCell>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontFamily: "Poppins",
                              elevation: 1,
                              preventDefault: true,
                            }}
                          >
                            <Checkbox
                              checked={z.selected || false}
                              onClick={(e) => {
                                e.stopPropagation();
                                let d = Object.assign([], data);
                                d[zidx].selected = e.target.checked;
                                setData(d);
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontFamily: "Poppins",
                              position: "relative",
                              display: "block",
                            }}
                          >
                            <FeatherIcon
                              icon="phone-incoming"
                              style={{
                                color: colours.black,
                                position: "absolute",
                                left: "0",
                                top: "7px",
                              }}
                            />
                            <span
                              style={{
                                marginLeft: "35px",
                                display: "inline-block",
                              }}
                            >
                              {z.file}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              fontFamily: "Poppins",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {z.agent}

                            <FeatherIcon
                              icon="edit-2"
                              onClick={async (e) => {
                                e.stopPropagation();
                                setagentname(z);
                                setEditagent(true);
                              }}
                              style={{
                                width: 16,
                                height: 16,
                                marginLeft: 16,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div style={{ fontFamily: "Poppins" }}>
                            {z.startdate.substr(0, 10)}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div style={{ fontFamily: "Poppins" }}>
                            {z.duration
                              ? Math.floor(z.duration / 60) +
                                "min " +
                                (z.duration % 60).toFixed(0) +
                                "sec"
                              : ""}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div style={{ fontFamily: "Poppins" }}>
                            {z.symblstatus === "completed" ? (
                              (z.VS || 0) + "%"
                            ) : z.symblstatus === "failed" ? (
                              <FeatherIcon
                                icon="alert-triangle"
                                size="20"
                                style={{
                                  marginLeft: 5,
                                  color: colours.orange100,
                                }}
                              />
                            ) : (
                              <CircularProgress
                                style={{
                                  color: colours.primary100,
                                  width: 20,
                                  height: 20,
                                }}
                              />
                            )}
                          </div>
                        </TableCell>

                        <TableCell>
                          {z.alertcount > 0 ? (
                            <div
                              style={{
                                color: colours.orange100,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {z.alertcount}
                              <FeatherIcon
                                icon="alert-triangle"
                                size="20"
                                style={{ marginLeft: 5 }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                color: colours.black,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignConversations;


function NewCampaignD1({ open, onClose, data }) {
  const [agentname, setagentname] = React.useState("");
  const [id, setid] = React.useState("");
  React.useEffect(() => {
    setagentname(data.agent);
    setid(data.id);
  }, [data]);
  return (
    <Dialog
      disableAutoFocus
      onClose={() => onClose(null)}
      onBackdropClick={() => {
        onClose(null);
      }}
      open={open}
      style={{
        padding: 0,

        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 30,
      }}
    >
      <DialogTitle style={{ padding: 0, minWidth: "600px", width: "600px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,

              fontFamily: "Poppins",
            }}
          >
            <div>Edit agent name</div>

            <FeatherIcon icon="x" style={{ color: "#ddd", cursor:"pointer" }}  onClick={()=>  onClose(null)}/>
          </div>
          <Divider style={{}} />
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            jus: "space-between",
            alignItems: "center",
            padding: 20,
            fontFamily: "Poppins",
          }}
        >
          <TextField
            id="outlined-basic"
            variant="outlined"
            style={{ width: "100%", marginTop: 20 }}
            label="Type"
            InputLabelProps={{
                style: { color: '#84858a' },
            }}
            multiline={true}
            value={agentname}
            onChange={(c) => {
              setagentname(c.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 20,
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              textTransform: "none",
              float: "right",
              marginRight: 5,
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Poppins",
              background: colours.white,
              color: colours.primary100,
              border: "1px solid #ddd",
              boxShadow: "none",
            }}
            variant="contained"
            onClick={() => onClose(null)}
          >
            Cancel
          </Button>
          <Button
            style={{
              textTransform: "none",
              float: "right",
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Poppins",
              background: colours.primary100,
            }}
            variant="contained"
            onClick={() => {
              onClose({ agentname, id });
            }}
          >
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
