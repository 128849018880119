import { Auth } from "aws-amplify";
import AWS from "aws-sdk";

const getLambdaClient = async () => {
  const credentials = await Auth.currentCredentials(); // Get credentials from Amplify
  AWS.config.update({
    region: "eu-west-1",
    credentials: credentials,
  });

  return new AWS.Lambda();
};

const S3_Util = {
  async callLambda(folderName) {
    if (!folderName) {
      throw new Error("Folder name is required.");
    }

    const lambda = await getLambdaClient();

    const params = {
      FunctionName: "create-s3-folder", // Replace with your Lambda function's name
      InvocationType: "RequestResponse", // Synchronous invocation
      Payload: JSON.stringify({ folderName }),
    };

    try {
      const response = await lambda.invoke(params).promise();
      const payload = JSON.parse(response.Payload);

      if (response.FunctionError) {
        throw new Error(`Lambda error: ${payload.errorMessage}`);
      }

      console.log("Lambda response:", payload);
      return payload;
    } catch (error) {
      console.error("Error invoking Lambda:", error);
      throw error;
    }
  },
};

export default S3_Util;
