import React, { createContext, useEffect, useState } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsconfig from "../../aws-exports";

// Ensure correct configuration for OAuth
awsconfig.oauth = {
  domain: "userpass.vocalysd.ai",
  scope: [
    "phone",
    "email",
    "openid",
    "profile",
    "aws.cognito.signin.user.admin",
  ],
  responseType: "code",
  redirectSignIn:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/"
      : "https://vault.vocalysd.ai/",
  redirectSignOut:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/"
      : "https://vault.vocalysd.ai/",
};

// Initialize Amplify
Amplify.configure(awsconfig);

// AuthContext Definition
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    user: null,
    isAuthenticated: false,
    isAdmin: false,
    userGroups: [],
    currentNonAdminGroup: null, // Add property to store the non-admin group
  });

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"] || [];
      const nonAdminGroup = groups.find((group) => group !== "admin") || null;

      setAuthState({
        user,
        isAuthenticated: true,
        isAdmin: groups.includes("admin"),
        userGroups: groups,
        currentNonAdminGroup: nonAdminGroup, // Set non-admin group
      });
    } catch {
      setAuthState({
        user: null,
        isAuthenticated: false,
        isAdmin: false,
        userGroups: [],
        currentNonAdminGroup: null, // Reset non-admin group
      });
    }
  };

  const setUserGroups = (groups) => {
    const nonAdminGroup = groups.find((group) => group !== "admin") || null;

    setAuthState((prevState) => ({
      ...prevState,
      userGroups: groups,
      isAdmin: groups.includes("admin"),
      currentNonAdminGroup: nonAdminGroup, // Update non-admin group
    }));
  };

  // Function to retrieve the JWT token
  const getJwtToken = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user.signInUserSession.idToken.jwtToken;
    } catch (error) {
      console.error("Failed to retrieve JWT token:", error);
      return null;
    }
  };

  useEffect(() => {
    checkUser();
    const listener = Hub.listen("auth", ({ payload: { event } }) => {
      if (["signIn", "cognitoHostedUI"].includes(event)) {
        checkUser();
      } else if (event === "signOut") {
        setAuthState({
          user: null,
          isAuthenticated: false,
          isAdmin: false,
          userGroups: [],
          currentNonAdminGroup: null, // Reset non-admin group
        });
      }
    });
    return () => Hub.remove("auth", listener);
  }, []);

  return (
    <AuthContext.Provider value={{ ...authState, setUserGroups, getJwtToken }}>
      {children}
    </AuthContext.Provider>
  );
};
