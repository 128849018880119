import React from "react";
import { colours, tabselected } from "../../consts.js";
import ConversationInsight from "./conversation-insight";
import ConversationTranscript from "./conversation-transcript";
import FeatherIcon from "feather-icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import Utils from "../../utils";
import Button from "@mui/material/Button";

function Conversation() {
  let location = useLocation();
  let navigate = useNavigate();
  const [selectedTab, setSelectedTab] = React.useState("Conversation insight");
  let conversationId = location.search.replace("?id=", "");
  const [campaignName, setCampaignName] = React.useState(conversationId);
  const [campaignId, setCampaignId] = React.useState(conversationId);
  const [uploadDiag, setUploadDiag] = React.useState(false);
  const [campaigndata, setCampaignData] = React.useState({
    name: "",
    type: "",
    company: { name: "" },
  });

  async function getConversationData() {
    let conversationId = location.search.replace("?id=", "");
    let t = await Utils.genericGet("getConversation", conversationId);
    setCampaignName(t.data.getConversation.campaign.name);
    setCampaignId(t.data.getConversation.campaign.id);
    setCampaignData(t.data.getConversation.campaign);
  }
  React.useEffect(() => {
    getConversationData();
  }, []);

  function returnToCampaigns() {
    navigate("/campaigns");
  }

  function returnToCampaign() {
    navigate("/campaign?id=" + campaignId);
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FeatherIcon
          icon="award"
          style={{ marginLeft: 20, marginBottom: 10 }}
        />
        <div
          style={{ marginLeft: 5, cursor: "pointer" }}
          onClick={() => returnToCampaigns()}
        >
          {" "}
          Campaigns /
        </div>
        <FeatherIcon icon="list" style={{ marginLeft: 5, marginBottom: 10 }} />
        <div
          style={{ marginLeft: 5, cursor: "pointer" }}
          onClick={() => returnToCampaign()}
        >
          {campaignName} /
        </div>
        <FeatherIcon
          icon="phone-call"
          style={{ marginLeft: 5, marginBottom: 10 }}
        />
        <div className="menu-item-selected">Conversation</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: colours.dividergraybottomborder,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
        >
          <div
            style={tabselected(selectedTab === "Conversation insight")}
            onClick={() => setSelectedTab("Conversation insight")}
          >
            Conversation insight
          </div>
          <div
            style={tabselected(selectedTab === "Transcript")}
            onClick={() => setSelectedTab("Transcript")}
          >
            Transcript
          </div>
        </div>
        <div style={{ paddingLeft: "1380px", paddingBottom: "10px" }}>
          <Button
            style={{
              textTransform: "none",
              float: "right",
              borderRadius: 20,
              marginRight: 20,
              marginTop: 10,
              background: colours.primary100,
              fontFamily: "Poppins",
            }}
            variant="contained"
            onClick={() => setUploadDiag(true)}
          >
            <FeatherIcon
              icon="upload"
              style={{ color: colours.white, marginRight: 8 }}
            />
            Upload
          </Button>
        </div>
      </div>

      {selectedTab === "Conversation insight" ? <ConversationInsight /> : null}
      {selectedTab === "Transcript" ? <ConversationTranscript /> : null}
    </div>
  );
}

export default Conversation;
