import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#999" : "#999",
  },
}));

const BorderLinearProgressP = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#4caf50" : "#4caf50",
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#333", // Background color of tooltip
    color: "#fff", // Font color
    fontSize: "14px", // Font size
    borderRadius: "8px", // Rounded corners
    padding: "8px 12px", // Padding inside the tooltip
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Shadow effect
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#333", // Arrow color matches tooltip background
  },
}));

const Topics = ({ 
  topichits, 
  capcase, 
  color,  
  maxItems, 
  sortKey = "n", 
  filterKey = "topic" 
}) => {
  if (!topichits || topichits.length === 0) {
    return null;
  }

  const filteredData = topichits
    .filter((item) => item[filterKey]) // Dynamic filter based on `filterKey`
    .sort((a, b) => b[sortKey] - a[sortKey]) // Sort by `sortKey`
    .slice(0, maxItems); // Limit items based on `maxItems`

  return (
    <div>
      <div>
        {filteredData.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                textAlign: "right",
                marginRight: 10,
                marginBottom: 5,
                fontSize: 12,
              }}
            >
              {capcase(item[filterKey])}
            </div>
            <Box
              sx={{
                color: color.subduedgray,
                width: "65%",
              }}
            >
              <CustomTooltip 
                title={`${item[sortKey]}`} // Display the count
                arrow 
                placement="top" 
                followCursor
              >
                {(item.s === "pos" || item.sen === "pos" ) ? (
                  <BorderLinearProgressP
                    variant="determinate"
                    color="inherit"
                    className="bar-positive"
                    value={Math.min(item[sortKey], 100)} // Value for bar fill
                  />
                ) : (item.s === "neg" || item.sen === "neg" ) ? (
                  <BorderLinearProgressP
                    variant="determinate"
                    color="inherit"
                    className="bar-negative"
                    value={Math.min(item[sortKey], 100)} // Value for bar fill
                  />
                ) : (
                  <BorderLinearProgress
                    variant="determinate"
                    color="inherit"
                    className="bar-neutral"
                    value={Math.min(item[sortKey], 100)} // Value for bar fill
                  />
                )}
              </CustomTooltip>
            </Box>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Topics;
