import React from "react";
import {
  Dialog,
  DialogTitle,
  Box,
  Button,
  Divider,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileDropZone from "./upload-file-drop-zone";
import { colours } from "../../consts";
import Utils from "../../utils";
import { AuthContext } from "../auth/AuthContext";
import ErrorIcon from "@mui/icons-material/Error";
import { Storage } from "aws-amplify";

function UploadDialogue({ open, onClose, campaigndata }) {
  const [uploading, setUploading] = React.useState(false);
  const [uploadComplete, setUploadComplete] = React.useState(false);
  const [uploadError, setUploadError] = React.useState(false);
  const [selectedTab] = React.useState("Upload Audio");
  const [files, setFiles] = React.useState([]);
  const [agentsFile, setAgentsFile] = React.useState([]); // For linking agents CSV
  const [agents, setAgents] = React.useState([]); // Parsed agent linking data

  const authContext = React.useContext(AuthContext);
  const { currentNonAdminGroup } = React.useContext(AuthContext);
  // Parse agents CSV if `agentsFile` changes
  React.useEffect(() => {
    if (agentsFile.length > 0) {
      readAgentFile(agentsFile[0]);
    }
  }, [agentsFile]);

  async function readAgentFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        const contents = e.target.result;
        const list = contents
          .split("\n")
          .filter((z) => z.trim() !== "")
          .map((z) => {
            const line = z.split(",");
            return {
              filename: line[0].trim(),
              agent: line[1] ? line[1].trim() : "No agent",
            };
          });
        setAgents(list);
        resolve(list);
      };
      reader.readAsText(file);
    });
  }

  const handleDone = async () => {
    if (files.length === 0) return;
    setUploading(true);
    setUploadError(false);

    try {
      const uploadedData = [];
      const today = new Date().toISOString().substr(0, 10);
      const sdate = today;
      const edate = today;
      let errorOccurred = false;

      for (const file of files) {
        try {
          const result = await Utils.savetos3(file, authContext);
          const key = result.key;

          let agent = "No agent";
          const found = agents.find((z) => z.filename === file.name);
          if (found) agent = found.agent;

          const conv = await Utils.genericMutation("createConversation", {
            name: key,
            agent,
            file: file.name,
            startdate: sdate,
            symblstatus: "uploaded",
            enddate: edate,
            campaignConversationsId: campaigndata?.id,
            groups: currentNonAdminGroup,
          });

          try {
            const response = await fetch(
              `https://8aia1juk87.execute-api.eu-west-1.amazonaws.com/production/symbl/upload?id=${conv.data.createConversation.id}`
            );

            if (!response.ok) {
              throw new Error(
                `Failed to trigger external processing: ${response.statusText}`,
                (errorOccurred = true)
              );
            }

            const data = await response.json();
            console.log("External processing triggered successfully:", data);
          } catch (error) {
            console.error("Error triggering external processing:", error);
          }

          uploadedData.push({
            originalname: file.name,
            newname: key,
            agent,
            startdate: sdate,
            enddate: edate,
          });

    

        } catch (error) {
          console.error("Error uploading file:", file.name, error);
          errorOccurred = true;
        }
      }

      setUploading(false);
      if (errorOccurred) {
        setUploadError(true);
      } else {
        setUploadComplete(true);
      }
    } catch (error) {
      console.error("Error during upload:", error);
      setUploading(false);
      setUploadError(true);
    }
  };

  const handleCancel = () => {
    setFiles([]);
    setAgentsFile([]);
    setAgents([]);
    setUploading(false);
    setUploadComplete(false);
    setUploadError(false);
  };

  const handleDialogClose = () => {
    // Reset state when closing the dialog after upload is done or error
    setFiles([]);
    setAgentsFile([]);
    setAgents([]);
    setUploading(false);
    setUploadComplete(false);
    setUploadError(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Upload Conversation
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />

      <Box
        sx={{
          position: "relative",
          padding: 2.5,
          minHeight: "200px",
          overflow: "hidden",
        }}
      >
        {uploadComplete && !uploadError && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CheckCircleIcon sx={{ fontSize: 50, color: "#df9508" }} />
            <Box
              sx={{
                mt: 2,
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#000", // Changed to black
              }}
            >
              <p color="#000">Upload Complete !</p>
            </Box>
          </Box>
        )}
        {uploadError && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorIcon sx={{ fontSize: 50, color: "red" }} />
            <Box
              sx={{
                mt: 2,
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#000", // Changed to black
              }}
            >
              <p color="#000">Issue Uploading Content !</p>
            </Box>
          </Box>
        )}

        {!uploadComplete && !uploadError && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <FileDropZone
                files={files}
                setFiles={setFiles}
                selectedTab={selectedTab}
              />
              <Box sx={{ marginTop: 2 }}>
                <Button
                  onClick={() => {
                    const link = document.createElement("a");
                    link.download = `template.csv`;
                    link.href = "/template.csv";
                    link.click();
                  }}
                  color="primary"
                  sx={{
                    color: colours.primary100,
                    textTransform: "none",
                    fontSize: 12,
                    fontFamily: "Poppins",
                    marginTop: 1,
                  }}
                >
                  Download template file here
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  marginTop: 2,
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                  disabled={uploading}
                  sx={{
                    borderColor: "#999",
                    color: "#666",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDone}
                  disabled={uploading || files.length === 0}
                  sx={{
                    backgroundColor: "#df9508",
                    "&:hover": {
                      backgroundColor: "#c78507",
                    },
                  }}
                >
                  {uploading ? "Uploading..." : "Start Upload"}
                </Button>
              </Box>
            </Box>
          </>
        )}

        {uploading && !uploadComplete && !uploadError && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0,0,0,0.1)",
              zIndex: 9999,
              borderRadius: "12px",
            }}
          >
            <CircularProgress
              sx={{
                color: "#df9508",
              }}
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
}

export default UploadDialogue;
